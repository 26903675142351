import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import useIsFullAdmin from "@src/hooks/useIsFullAdmin";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { Button } from "@src/ui/button";
import PlusIcon from "@src/ui/icons/18px/plus";
import Text from "@src/ui/text";
import InviteTraineeDialog from "@src/components/users/InviteTraineeDialog";
import NoPermissionsTooltip from "@src/utils/NoPermissionsTooltip";
import InviteAdminOrManagerDialog from "@src/components/users/InviteAdminOrManagerDialog";

const InviteUsersDropdown: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [adminOrManagerDialogOpen, setAdminOrManagerDialogOpen] =
    useState(false);
  const { userActionPermissions } = useActionPermissions();
  const isFullAdmin = useIsFullAdmin();

  const handleAdminOrManagerDialogOpen = () => {
    setAdminOrManagerDialogOpen(true);
    setDropdownOpen(false);
  };

  const handleQRCodeDialogOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    setQrDialogOpen(true);
    setDropdownOpen(false);
  };

  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger disabled={!userActionPermissions?.inviteOthers}>
          <NoPermissionsTooltip
            hasPermission={userActionPermissions?.inviteOthers}
          >
            <Button dataTestId="invite-users-button">
              <PlusIcon />
              Invite Users
            </Button>
          </NoPermissionsTooltip>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={handleQRCodeDialogOpen}>
            <Text type="P2" fontWeight="Regular">
              Trainee
            </Text>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleAdminOrManagerDialogOpen}>
            <Text type="P2" fontWeight="Regular">
              {isFullAdmin ? "Admin or Manager" : "Manager"}
            </Text>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <InviteTraineeDialog open={qrDialogOpen} onOpenChange={setQrDialogOpen} />
      <InviteAdminOrManagerDialog
        open={adminOrManagerDialogOpen}
        onOpenChange={setAdminOrManagerDialogOpen}
      />
    </>
  );
};

export default InviteUsersDropdown;

import React, { useState } from "react";
import { GroupType } from "@src/types.generated";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { Button } from "@ui/button";
import { Input } from "@ui/input";
import { ScrollArea } from "@ui/scroll-area";
import Text from "@ui/text";
import useIsFullAdmin from "@src/hooks/useIsFullAdmin";
import PlusIcon from "@src/ui/icons/18px/plus";

type ThinGroup = {
  id: number;
  name: string;
};

type Props<T extends ThinGroup> = {
  groups: T[];
  group?: T;
  setGroup: (group: T) => void;
  label?: string;
  groupType: GroupType;
  createGroup?: (name: string) => void;
};

const GroupSelect = <T extends ThinGroup>({
  groups,
  group,
  setGroup,
  label,
  groupType,
  createGroup,
}: Props<T>): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const isFullAdmin = useIsFullAdmin();
  const handleCreateGroup = () => {
    if (createGroup && newGroupName) {
      createGroup(newGroupName);
      setIsCreating(false);
      setNewGroupName("");
    }
  };
  return (
    <div className="w-full">
      {label && (
        <Text type="P2" fontWeight="SemiBold" className="mb-2">
          {label}
        </Text>
      )}
      <Select
        open={open}
        onOpenChange={setOpen}
        value={group?.id.toString()}
        onValueChange={(value) => {
          const selectedGroup = groups.find((g) => g.id.toString() === value);
          if (selectedGroup) setGroup(selectedGroup);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={`Select ${groupType.toLowerCase()}`} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <ScrollArea className="h-[200px]">
              {groups.map((item) => (
                <SelectItem key={item.id} value={item.id.toString()}>
                  {item.name}
                </SelectItem>
              ))}
              {groups.length === 0 && (
                <div className="p-2 text-sm text-muted-foreground">
                  No {groupType.toLowerCase()} found
                </div>
              )}
            </ScrollArea>
          </SelectGroup>
          {isFullAdmin && createGroup && !isCreating && (
            <div className="flex justify-center border-t border-border p-1 pt-2">
              <Button
                variant="ghost"
                className="w-full text-primary hover:bg-blue-1"
                onClick={() => setIsCreating(true)}
              >
                <PlusIcon className="size-4" />
                Create {groupType.toLowerCase()}
              </Button>
            </div>
          )}
          {isCreating && (
            <div className="p-2">
              <Input
                placeholder={`New ${groupType.toLowerCase()} name`}
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                className="mb-2"
              />
              <div className="flex justify-end space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setIsCreating(false)}
                >
                  Cancel
                </Button>
                <Button size="sm" onClick={handleCreateGroup}>
                  Create
                </Button>
              </div>
            </div>
          )}
        </SelectContent>
      </Select>
    </div>
  );
};

export default GroupSelect;

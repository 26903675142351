import * as React from "react";
import { IconProps } from "../types";

const SquaresCopyOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M7.25 16.25H14.25C15.3546 16.25 16.25 15.3546 16.25 14.25V7.25C16.25 6.14543 15.3546 5.25 14.25 5.25L7.25 5.25C6.14543 5.25 5.25 6.14543 5.25 7.25L5.25 14.25C5.25 15.3546 6.14543 16.25 7.25 16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12.605C2.267 12.308 1.75 11.59 1.75 10.75V3.75C1.75 2.645 2.645 1.75 3.75 1.75H10.75C11.589 1.75 12.308 2.267 12.605 3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default SquaresCopyOutlineIcon;

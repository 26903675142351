import * as React from "react";
import { IconProps } from "../types";

const PrintOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M5.75 5.25V2.75C5.75 2.198 6.198 1.75 6.75 1.75H11.25C11.802 1.75 12.25 2.198 12.25 2.75V5.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.75 13.25H4.25C3.145 13.25 2.25 12.355 2.25 11.25V7.25C2.25 6.145 3.145 5.25 4.25 5.25H13.75C14.855 5.25 15.75 6.145 15.75 7.25V11.25C15.75 12.355 14.855 13.25 13.75 13.25H12.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 9.75V15.25C12.25 15.802 11.802 16.25 11.25 16.25H6.75C6.198 16.25 5.75 15.802 5.75 15.25V9.75H12.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default PrintOutlineIcon;

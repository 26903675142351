import React from "react";
import { cn } from "@src/ui/lib/utils";
import ScrollableFlexGrow from "@src/components/ui/flexBox/ScrollableFlexGrow";
import Text from "@ui/text";
import { Skeleton } from "@src/ui/skeleton";
import { Button } from "@src/ui/button";
import { Route } from "nextjs-routes";
import ArrowLeftIcon from "@src/ui/icons/18px/arrow-left";
import Link from "next/link";
import ChevronRightIcon from "@src/ui/icons/12px/chevron-right";

type Props = {
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
  learnMoreLink?: string;
  dataTestId?: string;
};

const ListPage: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-1 self-stretch" data-testid={props.dataTestId}>
      <ScrollableFlexGrow
        className={cn("hide-scrollbar", props.className)}
        innerStyle={{ padding: "32px 32px 0 32px", gap: 24 }}
        alignSelf={"stretch"}
        flex={1}
      >
        {props.children}
      </ScrollableFlexGrow>
    </div>
  );
};

const ListPageHeader: React.FC<Props> = (props) => {
  return (
    <div className={cn("relative flex flex-col gap-2 w-full", props.className)}>
      {props.children}
    </div>
  );
};

type ListPageBackButtonProps = {
  route: Route;
  children: React.ReactNode;
};
const ListPageBackButton: React.FC<ListPageBackButtonProps> = (props) => {
  return (
    <div>
      <Link href={props.route} className="group mb-4 flex items-center gap-3">
        <ArrowLeftIcon className="size-4 text-muted-foreground transition-all group-hover:text-foreground" />
        <Text
          type="P3"
          fontWeight="Medium"
          className="text-muted-foreground transition-all group-hover:text-foreground"
        >
          {props.children}
        </Text>
      </Link>
    </div>
  );
};

const ListPageBackButtonLoading: React.FC = () => {
  return (
    <div className="mb-4 flex items-center gap-3">
      <Skeleton className="size-4" />
      <Skeleton className="h-4 w-24" />
    </div>
  );
};

const ListPageTitleAvatar: React.FC<Props> = (props) => {
  return (
    <div className="flex size-10 items-center justify-center overflow-hidden rounded-lg border border-gray-2 bg-gray-2">
      {props.children}
    </div>
  );
};

const ListPageTitle: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Skeleton className="h-8 w-24 rounded bg-gray-2" />;
  }
  return (
    <Text
      type="H2"
      fontWeight="SemiBold"
      className={cn("flex gap-2 items-center", props.className)}
    >
      {props.children}
    </Text>
  );
};

const ListPageSubtitle: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Skeleton className="h-4 w-1/2 rounded bg-gray-2" />;
  }
  return (
    <div>
      <Text
        type="P2"
        className={cn("w-1/2 text-muted-foreground", props.className)}
        multiline
      >
        {props.children}
      </Text>
      {props.learnMoreLink && (
        <a href={props.learnMoreLink} target="_blank" rel="noreferrer">
          <Button variant="link" className="pl-0">
            Learn more
          </Button>
        </a>
      )}
    </div>
  );
};

const ListPageTitleCount: React.FC<Props> = (props) => {
  if (props.loading) {
    return (
      <div className="inline-flex min-h-[22px] min-w-[22px] items-center justify-center rounded-md bg-input px-1 py-0.5">
        <Skeleton className="h-2 w-3 rounded-sm bg-gray-5" />
      </div>
    );
  }
  return (
    <div className="inline-flex min-h-[22px] min-w-[22px] items-center justify-center rounded-md bg-input px-1 py-0.5">
      <Text
        type="P3"
        fontWeight="SemiBold"
        className={cn("text-muted-foreground", props.className)}
      >
        {props.children}
      </Text>
    </div>
  );
};

const ListPageActions: React.FC<Props> = (props) => {
  return (
    <div className={cn("absolute flex gap-2.5 right-0 top-0", props.className)}>
      {props.children}
    </div>
  );
};

const ListPageContent: React.FC<Props> = (props) => {
  return (
    <div className={cn("flex flex-col gap-2 flex-1 w-full", props.className)}>
      {props.children}
    </div>
  );
};

const ListPageLoading: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col gap-6 self-stretch p-8">
      <div className="flex gap-2">
        <div className="flex flex-1 flex-col gap-2">
          <Skeleton className="h-8 w-40" />
          <Skeleton className="h-6 w-1/3" />
        </div>
        <Skeleton className="h-8 w-24" />
      </div>
      <div className="flex flex-1 items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <Skeleton className="h-8 w-20" />
          <Skeleton className="h-8 w-20" />
          <Skeleton className="h-8 w-20" />
        </div>
        <div className="flex items-center justify-end gap-2">
          <Skeleton className="h-8 w-20" />
          <Skeleton className="h-8 w-20" />
          <Skeleton className="h-8 w-60" />
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col">
        {Array.from({ length: 40 }).map((_, index) => (
          <div
            key={index}
            className="flex flex-1 gap-8 border-b border-gray-1 py-2"
          >
            <div className="flex items-center gap-2">
              <Skeleton className="size-7" />
              <Skeleton className="h-4 w-60" />
            </div>
            <Skeleton className="h-4 w-32" />
            <Skeleton className="h-4 w-32" />
            <div className="flex flex-1 justify-end">
              <Skeleton className="h-4 w-24" />
            </div>
            <div className="flex flex-1 justify-end">
              <Skeleton className="h-4 w-24" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export type ListPageBreadcrumb = {
  id: string;
  label: string;
  route?: Route | null | undefined;
};
type ListPageHeaderBreadcrumbsProps = {
  showTrailingSlash?: boolean;
  includeLinks?: boolean;
  breadcrumbs: ListPageBreadcrumb[];
};
const ListPageHeaderBreadcrumbs: React.FC<ListPageHeaderBreadcrumbsProps> = (
  props,
) => {
  return (
    <div className="flex flex-wrap items-center">
      {props.breadcrumbs.map((x, i) => {
        return (
          <div key={x.id} className="mb-2 flex items-center gap-1.5">
            <Text
              type="P3"
              fontWeight="Medium"
              className="text-muted-foreground"
              skeletonWidth={280}
              link={props.includeLinks ? x.route : undefined}
              style={{
                marginLeft: 2,
                wordBreak: "break-word",
                overflow: "hidden",
              }}
            >
              {x.label}
            </Text>
            {!(
              !props.showTrailingSlash && i === props.breadcrumbs.length - 1
            ) && (
              <ChevronRightIcon className="size-2.5 text-muted-foreground" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export {
  ListPage,
  ListPageHeader,
  ListPageTitle,
  ListPageSubtitle,
  ListPageActions,
  ListPageContent,
  ListPageTitleCount,
  ListPageLoading,
  ListPageBackButton,
  ListPageHeaderBreadcrumbs,
  ListPageBackButtonLoading,
  ListPageTitleAvatar,
};

import React, { useState, useMemo } from "react";
import { GroupType } from "@src/types.generated";
import { Button } from "@ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@ui/dialog";
import Text from "@ui/text";
import {
  InviteQrCodeModalQuery,
  useCreateLocationInQrCodeModalMutation,
  useInviteQrCodeModalQuery,
} from "@components/modals/invitePeople/operations.generated";
import { GraphqlOperations } from "@src/types.generated";
import { useTracking } from "@hooks/useTracking";
import useActionPermissions from "@hooks/useActionPermissions";
import QueryStatus from "@components/layout/QueryStatus";
import GroupSelect from "@components/roles/GroupSelect";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import PrintOutlineIcon from "@src/ui/icons/18px/print-outline";
import SquaresCopyOutlineIcon from "@src/ui/icons/18px/squares-copy-outline";

type Location = InviteQrCodeModalQuery["Locations"]["objects"][0];

type Props = {
  initialLocationId?: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const InviteTraineeDialog: React.FC<Props> = ({
  initialLocationId,
  open,
  onOpenChange,
}) => {
  const [locationId, setLocationId] = useState<number | null>(
    initialLocationId ?? null,
  );
  const { error, loading: queryLoading, data } = useInviteQrCodeModalQuery();
  const locations = useMemo(() => data?.Locations.objects || [], [data]);
  const selectedLocation = useMemo(
    () => locations.find((l: Location) => l.id === locationId),
    [locations, locationId],
  );
  const { userActionPermissions } = useActionPermissions();
  const { trackEvent } = useTracking();

  const [createLocationMutation] = useCreateLocationInQrCodeModalMutation({
    refetchQueries: [GraphqlOperations.Query.InviteQRCodeModal],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.createLocation.location) {
        setLocationId(data.createLocation.location.id);
        trackEvent("created_location", { id: data.createLocation.location.id });
      }
    },
  });

  const createLocation = (name: string) => {
    createLocationMutation({
      variables: {
        input: { name },
      },
    });
  };

  if (!data) {
    return <QueryStatus loading={queryLoading} error={error} />;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Invite QR Code</DialogTitle>
        </DialogHeader>
        <GroupSelect
          groups={locations}
          group={selectedLocation}
          setGroup={(group) => setLocationId(group.id)}
          groupType={GroupType.Location}
          createGroup={
            userActionPermissions?.changeOrgSettings
              ? createLocation
              : undefined
          }
        />
        {selectedLocation == null || selectedLocation?.signupCode == null ? (
          <div className="mt-2 flex min-h-[320px] flex-col items-center justify-center rounded-lg border border-input p-4">
            <img
              src="/images/qr-code.png"
              className="size-24"
              alt="qr-code-empty-state"
            />
            <Text type="P2" className="mt-6 text-center text-muted-foreground">
              Select a location
            </Text>
          </div>
        ) : (
          <div className="mt-2 flex min-h-[320px] flex-col items-center justify-center rounded-lg border border-input p-4">
            <img
              className="size-44"
              src={selectedLocation.signupCode.signupQrcodeLink}
              alt="qr-code"
            />
            <Text type="P2" className="mt-2">
              Location:{" "}
              <span className="font-semibold">{selectedLocation.name}</span>
            </Text>
            <Text type="P2" className="mt-1">
              Manual sign up code:{" "}
              <span className="font-semibold">
                {selectedLocation.signupCode.signupLink.split("/").pop()}
              </span>
            </Text>
            <div className="mt-4 flex justify-center gap-2 self-stretch">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="flex-1">
                    <PrintOutlineIcon className="mr-2 size-4" />
                    Print as flyer
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => {
                      window.open(`/group/${selectedLocation.id}/poster`);
                    }}
                  >
                    English
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      window.open(
                        `/group/${selectedLocation.id}/spanish-poster`,
                      );
                    }}
                  >
                    Spanish
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                variant="outline"
                className="flex-1"
                onClick={() => {
                  navigator.clipboard.writeText(
                    selectedLocation.signupCode!.signupLink,
                  );
                }}
              >
                <SquaresCopyOutlineIcon className="mr-2 size-4" />
                Copy as URL
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InviteTraineeDialog;

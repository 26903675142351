import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { EmployeeThinFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchBlastRecipientsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchBlastRecipientsQuery = { __typename?: 'Query', Employees: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, name: string, phoneNumber?: string | null, language: Language, userType: Types.UserType }> } };

export type AddUsersToLocationsMutationVariables = Types.Exact<{
  input: Types.AddUsersToLocationsInput;
}>;


export type AddUsersToLocationsMutation = { __typename?: 'Mutation', addUsersToLocations: { __typename?: 'LocationsMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };

export type AddUsersToRolesMutationVariables = Types.Exact<{
  input: Types.AddUsersToRolesInput;
}>;


export type AddUsersToRolesMutation = { __typename?: 'Mutation', addUsersToRoles: { __typename?: 'RolesMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };

export type RemoveUsersFromLocationsMutationVariables = Types.Exact<{
  locationIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  employeeIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type RemoveUsersFromLocationsMutation = { __typename?: 'Mutation', removeUsersFromLocations: { __typename?: 'LocationsMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };

export type RemoveUsersFromRolesMutationVariables = Types.Exact<{
  roleIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  employeeIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type RemoveUsersFromRolesMutation = { __typename?: 'Mutation', removeUsersFromRoles: { __typename?: 'RolesMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };


export const SearchBlastRecipientsDocument = gql`
    query SearchBlastRecipients($limit: Int, $search: String) {
  Employees: AdminEmployees(limit: $limit, search: $search) {
    totalCount
    objects {
      ...EmployeeThin
    }
  }
}
    ${EmployeeThinFragmentDoc}`;

/**
 * __useSearchBlastRecipientsQuery__
 *
 * To run a query within a React component, call `useSearchBlastRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBlastRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBlastRecipientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBlastRecipientsQuery(baseOptions?: Apollo.QueryHookOptions<SearchBlastRecipientsQuery, SearchBlastRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBlastRecipientsQuery, SearchBlastRecipientsQueryVariables>(SearchBlastRecipientsDocument, options);
      }
export function useSearchBlastRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBlastRecipientsQuery, SearchBlastRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBlastRecipientsQuery, SearchBlastRecipientsQueryVariables>(SearchBlastRecipientsDocument, options);
        }
export type SearchBlastRecipientsQueryHookResult = ReturnType<typeof useSearchBlastRecipientsQuery>;
export type SearchBlastRecipientsLazyQueryHookResult = ReturnType<typeof useSearchBlastRecipientsLazyQuery>;
export type SearchBlastRecipientsQueryResult = Apollo.QueryResult<SearchBlastRecipientsQuery, SearchBlastRecipientsQueryVariables>;
export const AddUsersToLocationsDocument = gql`
    mutation addUsersToLocations($input: AddUsersToLocationsInput!) {
  addUsersToLocations(input: $input) {
    success
    error {
      code
    }
  }
}
    `;
export type AddUsersToLocationsMutationFn = Apollo.MutationFunction<AddUsersToLocationsMutation, AddUsersToLocationsMutationVariables>;

/**
 * __useAddUsersToLocationsMutation__
 *
 * To run a mutation, you first call `useAddUsersToLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToLocationsMutation, { data, loading, error }] = useAddUsersToLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUsersToLocationsMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToLocationsMutation, AddUsersToLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToLocationsMutation, AddUsersToLocationsMutationVariables>(AddUsersToLocationsDocument, options);
      }
export type AddUsersToLocationsMutationHookResult = ReturnType<typeof useAddUsersToLocationsMutation>;
export type AddUsersToLocationsMutationResult = Apollo.MutationResult<AddUsersToLocationsMutation>;
export type AddUsersToLocationsMutationOptions = Apollo.BaseMutationOptions<AddUsersToLocationsMutation, AddUsersToLocationsMutationVariables>;
export const AddUsersToRolesDocument = gql`
    mutation addUsersToRoles($input: AddUsersToRolesInput!) {
  addUsersToRoles(input: $input) {
    success
    error {
      code
    }
  }
}
    `;
export type AddUsersToRolesMutationFn = Apollo.MutationFunction<AddUsersToRolesMutation, AddUsersToRolesMutationVariables>;

/**
 * __useAddUsersToRolesMutation__
 *
 * To run a mutation, you first call `useAddUsersToRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToRolesMutation, { data, loading, error }] = useAddUsersToRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUsersToRolesMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToRolesMutation, AddUsersToRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToRolesMutation, AddUsersToRolesMutationVariables>(AddUsersToRolesDocument, options);
      }
export type AddUsersToRolesMutationHookResult = ReturnType<typeof useAddUsersToRolesMutation>;
export type AddUsersToRolesMutationResult = Apollo.MutationResult<AddUsersToRolesMutation>;
export type AddUsersToRolesMutationOptions = Apollo.BaseMutationOptions<AddUsersToRolesMutation, AddUsersToRolesMutationVariables>;
export const RemoveUsersFromLocationsDocument = gql`
    mutation removeUsersFromLocations($locationIds: [Int!], $employeeIds: [Int!]!) {
  removeUsersFromLocations(locationIds: $locationIds, userIds: $employeeIds) {
    success
    error {
      code
    }
  }
}
    `;
export type RemoveUsersFromLocationsMutationFn = Apollo.MutationFunction<RemoveUsersFromLocationsMutation, RemoveUsersFromLocationsMutationVariables>;

/**
 * __useRemoveUsersFromLocationsMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromLocationsMutation, { data, loading, error }] = useRemoveUsersFromLocationsMutation({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useRemoveUsersFromLocationsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUsersFromLocationsMutation, RemoveUsersFromLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUsersFromLocationsMutation, RemoveUsersFromLocationsMutationVariables>(RemoveUsersFromLocationsDocument, options);
      }
export type RemoveUsersFromLocationsMutationHookResult = ReturnType<typeof useRemoveUsersFromLocationsMutation>;
export type RemoveUsersFromLocationsMutationResult = Apollo.MutationResult<RemoveUsersFromLocationsMutation>;
export type RemoveUsersFromLocationsMutationOptions = Apollo.BaseMutationOptions<RemoveUsersFromLocationsMutation, RemoveUsersFromLocationsMutationVariables>;
export const RemoveUsersFromRolesDocument = gql`
    mutation removeUsersFromRoles($roleIds: [Int!], $employeeIds: [Int!]!) {
  removeUsersFromRoles(roleIds: $roleIds, userIds: $employeeIds) {
    success
    error {
      code
    }
  }
}
    `;
export type RemoveUsersFromRolesMutationFn = Apollo.MutationFunction<RemoveUsersFromRolesMutation, RemoveUsersFromRolesMutationVariables>;

/**
 * __useRemoveUsersFromRolesMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromRolesMutation, { data, loading, error }] = useRemoveUsersFromRolesMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useRemoveUsersFromRolesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUsersFromRolesMutation, RemoveUsersFromRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUsersFromRolesMutation, RemoveUsersFromRolesMutationVariables>(RemoveUsersFromRolesDocument, options);
      }
export type RemoveUsersFromRolesMutationHookResult = ReturnType<typeof useRemoveUsersFromRolesMutation>;
export type RemoveUsersFromRolesMutationResult = Apollo.MutationResult<RemoveUsersFromRolesMutation>;
export type RemoveUsersFromRolesMutationOptions = Apollo.BaseMutationOptions<RemoveUsersFromRolesMutation, RemoveUsersFromRolesMutationVariables>;